import { Dropdown } from "antd";
import { ArrowRightOutlined, CopyOutlined, DeleteOutlined, EditOutlined, MoreOutlined, RollbackOutlined, ShareAltOutlined } from '@ant-design/icons';
import { useRef, useState } from "react";

import { Category, Document } from "interfaces/documents";
import { BaseModalRef } from "modals/BaseModal";
import CategoryRenameModal from "modals/CategoryRenameModal";
import CategoriesTreeModal from "modals/CategoriesTreeModal";
import ConfirmationModal from "modals/ConfirmationModal";
import DocumentRenameModal from "modals/DocumentRenameModal";
import DocumentVersionsModal from "modals/DocumentVersionsModal";
import GuestTeamModal from "modals/GuestTeamModal";
import { moveCategory, softDeleteCategory } from "slices/categoriesActions";
import { setExcluded } from "slices/categoriesSlice";
import { setCategory } from "slices/categorySlice";
import { setDocument } from "slices/documentSlice";
import { copyDocument, moveDocument, softDeleteDocument } from "slices/documentsActions";
import { useAppDispatch, useAppSelector } from "utilities/hooks";

const ItemActionList = ({
    fetchData,
    row,
}: {
    fetchData: () => void,
    row: Category | Document,
}) => {
    const dispatch = useAppDispatch();
    const {
        teams,
    } = useAppSelector((state) => state.documents);
    const [currentItem, setCurrentItem] = useState<null | any>(null);
    const [currentTeam, setCurrentTeam] = useState<null | any>(null);

    const categoryRenameModal = useRef<BaseModalRef>(null);
    const confirmRowDeleteModal = useRef<BaseModalRef>(null);
    const copyRowModal = useRef<BaseModalRef>(null);
    const documentRenameModal = useRef<BaseModalRef>(null);
    const documentVersionsModal = useRef<BaseModalRef>(null);
    const guestTeamModal = useRef<BaseModalRef>(null);
    const moveRowModal = useRef<BaseModalRef>(null);

    const openGuestTeamModal = (item: any) => {
        let team: any = null;
        if (item.type === 'category') {
            team = teams[item?.uuid];
        } else {
            team = teams[item?.id];
        }

        setCurrentTeam(team);

        guestTeamModal?.current?.open();
    };

    const openVersions = (item: any) => {
        dispatch(setDocument(item));
        documentVersionsModal.current?.open();
    };

    const openCategoryRenameModal = (item: Category) => {
        dispatch(setCategory(item));
        categoryRenameModal.current?.open();
    };

    const openDocumentRenameModal = (item: Document) => {
        dispatch(setDocument(item));
        documentRenameModal.current?.open();
    };

    const rename = (item: any | Array<any>) => {
        if (!item) return;
        if (Array.isArray(item)) {
            if (item.length === 0) return;
            item = item[0];
        }
        setCurrentItem(item);
        if ((item.type === 'category')) {
            openCategoryRenameModal(item);
        } else {
            openDocumentRenameModal(item);
        }
    };

    const openCopyRowModal = (item: any) => {
        if (!item) return;
        setCurrentItem(item);

        copyRowModal.current?.open();
    };

    const copyItem = (destination: Category | null) => {
        let promises: any[] = [];

        promises.push(dispatch(copyDocument({
            id: currentItem.id,
            category_id: destination?.id || null,
        })));

        setCurrentItem(null);
        fetchData();
    };

    const openMoveModal = (item: any) => {
        if (!item) return;
        setCurrentItem(item);
        if (item.type === 'category') {
            dispatch(setExcluded([item]));
        }

        moveRowModal.current?.open();
    };

    const moveItem = (destination: Category | null) => {
        let promises: any[] = [];

        if (currentItem.type === 'category') {
            promises.push(dispatch(moveCategory({
                id: currentItem.id,
                parent_id: destination?.id || null,
            })));
        } else {
            promises.push(dispatch(moveDocument({
                id: currentItem.id,
                category_id: destination?.id || null,
            })));
        }

        setCurrentItem(null);
        fetchData();
    };

    const openDeleteItemModal = (item: any) => {
        if (!item) return;
        setCurrentItem(item);
        confirmRowDeleteModal.current?.open();
    };

    const deleteItem = (hasConfirmed: Boolean) => {
        if (!hasConfirmed) return;

        let promises: any[] = [];
        if (currentItem.type === 'category') {
            promises.push(dispatch(softDeleteCategory(currentItem.id)));
        } else {
            promises.push(dispatch(softDeleteDocument(currentItem.id)));
        }

        setCurrentItem(null);
        fetchData();
    };

    var items = [
        !(row.type == 'category' && (row as Category).is_locked) ? {
            key: 'ManageSharing',
            label: 'Manage Sharing',
            onClick: () => {
                openGuestTeamModal(row);
            },
            icon: <ShareAltOutlined />
        } : null,
        !(row.type == 'category' || (row as Category).is_locked) ? {
            key: 'Versions',
            label: 'Versions',
            onClick: () => {
                openVersions(row);
            },
            icon: <RollbackOutlined />
        } : null,
        !((row as Category).is_locked) ?{
            key: 'Rename',
            label: 'Rename',
            onClick: () => {
                rename(row);
            },
            icon: <EditOutlined />,
        } : null,
        !(row.type == 'category' || (row as Category).is_locked) ? {
            key: 'Copy',
            label: 'Copy',
            onClick: () => {
                openCopyRowModal(row);
            },
            icon: <CopyOutlined />,
        } : null,
        !((row as Category).is_locked) ? {
            key: 'Move',
            label: 'Move',
            onClick: () => {
                openMoveModal(row);
            },
            icon: <ArrowRightOutlined />,
        } : null,
        !((row as Category).is_locked) ? {
            key: 'Delete',
            label: 'Delete',
            onClick: () => {
                openDeleteItemModal(row);
            },
            icon: <DeleteOutlined />,
        } : null,
    ];
    items = items.filter((item) => item);

    return (
        <>
            <Dropdown menu={{ items }}>
                <a
                    onClick={(e) => {
                        e.preventDefault();
                    }}
                >
                    <MoreOutlined />
                </a>
            </Dropdown>

            <CategoryRenameModal
                ref={categoryRenameModal}
                onSubmit={fetchData}
            ></CategoryRenameModal>
            <CategoriesTreeModal
                ref={copyRowModal}
                title={`Copy ${currentItem?.type == 'category' ? 'folder' : 'document'} to...`}
                submitLabel='Copy'
                onSubmit={copyItem}
            ></CategoriesTreeModal>
            <CategoriesTreeModal
                ref={moveRowModal}
                title={`Move ${currentItem?.type == 'category' ? 'folder' : 'document'} to...`}
                submitLabel='Move'
                onSubmit={moveItem}
            ></CategoriesTreeModal>
            <ConfirmationModal
                ref={confirmRowDeleteModal}
                text='Are you sure want to delete item?'
                onConfirm={deleteItem}
            ></ConfirmationModal>
            <DocumentRenameModal
                ref={documentRenameModal}
                onSubmit={fetchData}
            ></DocumentRenameModal>
            <DocumentVersionsModal
                ref={documentVersionsModal}
                onClose={fetchData}
            ></DocumentVersionsModal>
            <GuestTeamModal
                ref={guestTeamModal}
                onClose={fetchData}
                entity={row}
            ></GuestTeamModal>
        </>
    );
}

export default ItemActionList;
