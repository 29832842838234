import {
    Grid,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Menu,
    MenuItem,
    Typography,
    Divider,
} from "@mui/material";
import {
    DeleteSweep,
    DriveFileMove,
    People,
    Share,
    SyncAlt,
} from "@mui/icons-material";
import { bindMenu } from "material-ui-popup-state/hooks";
import { EntityContext, TeamsContext } from "interfaces/teams";
import CustomBreadCrumbs from "components/CustomBreadCrumbs";
import { useDocumentsPage } from "hooks/useDocumentsPage";
import TeamCreateModal from "modals/TeamCreateModal";
import TeamRenameModal from "modals/TeamRenameModal";
import TeamMembersUpdateModal from "modals/TeamMembersUpdateModal";
import ConfirmationModal from "modals/ConfirmationModal";
import GuestTeamCreateModal from "modals/GuestTeamCreateModal";
import GuestTeamMembersUpdateModal from "modals/GuestTeamMembersUpdateModal";
import CategoriesTreeModal from "modals/CategoriesTreeModal";
import NewDocumentsTable from "../components/NewDocumentsTable";

const DefaultDocumentsPage = () => {
    const {
        currentTeam,
        integrations,
        breadcrumbs,
        loading,
        currentBreadcrumb,
        setCurrentBreadcrumb,
        currentEntity,
        teamCreateModal,
        teamMembersUpdateModal,
        teamRenameModal,
        confirmTeamDeleteModal,
        confirmDeleteBreadcrumbModal,
        guestTeamCreateModal,
        guestTeamMembersUpdateModal,
        moveRowModal,
        breadcrumbPopupState,
        rootBreadcrumbPopupState,
        fetchData,
        handleSyncIntegration,
        handleConfirmTeamDelete,
        openGuestTeamModal,
        openMoveModal,
        moveCurrentBreadcrumb,
        handleConfirmBreadcrumbDelete,
        navigateTo,
    } = useDocumentsPage();

    return (
        <>
            <Grid item xs={8} sx={{ pb: 2 }}>
                <CustomBreadCrumbs
                    title={currentTeam ? currentTeam.name : "Documents"}
                    loading={loading}
                    breadcrumbs={breadcrumbs}
                    onRootClick={() => {
                        navigateTo();
                    }}
                    onClick={(breadcrumb) => {
                        navigateTo(breadcrumb.id);
                    }}
                // onMenuClick={(event, breadcrumb) => {
                //     setCurrentBreadcrumb(breadcrumb);
                //     breadcrumbPopupState.open(event);
                // }}
                // onAccessClick={(event) => {
                //     rootBreadcrumbPopupState.open(event);
                // }}
                />
            </Grid>

            {/* <DocumentsTable */}

            <NewDocumentsTable hasFileTree={true} />

            <Menu
                elevation={1}
                {...bindMenu(breadcrumbPopupState)}
                PaperProps={{
                    style: {
                        width: 220,
                    },
                }}
                MenuListProps={{
                    dense: false,
                    subheader: (
                        <ListSubheader>
                            <Typography variant="subtitle2" my={2} noWrap>
                                {currentBreadcrumb?.name || currentTeam?.name}
                            </Typography>
                        </ListSubheader>
                    ),
                }}
            >
                <MenuItem
                    onClick={() => {
                        breadcrumbPopupState.close();
                        openGuestTeamModal(currentBreadcrumb);
                    }}
                >
                    <ListItemIcon>
                        <Share fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Manage Sharing</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={openMoveModal}>
                    <ListItemIcon>
                        <DriveFileMove fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Move</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => confirmDeleteBreadcrumbModal.current?.open()}>
                    <ListItemIcon>
                        <DeleteSweep fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
            </Menu>
            <Menu
                elevation={1}
                {...bindMenu(rootBreadcrumbPopupState)}
                PaperProps={{
                    style: {
                        width: 220,
                    },
                }}
                MenuListProps={{
                    dense: false,
                    subheader: (
                        <ListSubheader>
                            <Typography variant="subtitle2" my={2} noWrap>
                                {currentTeam ? currentTeam?.name : "Documents"}
                            </Typography>
                        </ListSubheader>
                    ),
                }}
            >
                <MenuItem onClick={() => teamRenameModal.current?.open()}>
                    <ListItemIcon>
                        <DriveFileMove fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Rename</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => teamMembersUpdateModal.current?.open()}>
                    <ListItemIcon>
                        <People fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Manage members</ListItemText>
                </MenuItem>
                {integrations && integrations.length > 0 && <Divider />}
                {integrations &&
                    integrations.map(
                        (integration, index) =>
                            integration.status === "active" && (
                                <MenuItem
                                    key={index}
                                    onClick={() => handleSyncIntegration(integration)}
                                >
                                    <ListItemIcon>
                                        <SyncAlt fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText sx={{ textTransform: "capitalize" }}>
                                        {integration.name} Sync
                                    </ListItemText>
                                </MenuItem>
                            )
                    )}
                <Divider />
                <MenuItem onClick={() => confirmTeamDeleteModal.current?.open()}>
                    <ListItemIcon>
                        <DeleteSweep fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
            </Menu>

            <EntityContext.Provider value={currentEntity}>
                <GuestTeamCreateModal ref={guestTeamCreateModal} onClose={fetchData} />
                <GuestTeamMembersUpdateModal
                    ref={guestTeamMembersUpdateModal}
                    onClose={fetchData}
                />
            </EntityContext.Provider>

            <ConfirmationModal
                ref={confirmDeleteBreadcrumbModal}
                text="Are you sure you want to delete this item?"
                onConfirm={handleConfirmBreadcrumbDelete}
            />
            <ConfirmationModal
                ref={confirmTeamDeleteModal}
                text="Are you sure you want to delete this team?"
                onConfirm={handleConfirmTeamDelete}
            />

            <TeamsContext.Provider value={currentTeam}>
                <TeamCreateModal ref={teamCreateModal} onClose={fetchData} />
                <TeamRenameModal ref={teamRenameModal} onClose={fetchData} />
                <TeamMembersUpdateModal
                    ref={teamMembersUpdateModal}
                    onClose={fetchData}
                />
            </TeamsContext.Provider>

            <CategoriesTreeModal
                ref={moveRowModal}
                title={`Move category to...`}
                submitLabel="Move"
                onSubmit={moveCurrentBreadcrumb}
            />
        </>
    );
};

export default DefaultDocumentsPage;
