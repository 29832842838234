import {
    Box,
    Grid,
} from '@mui/material';
import CustomBreadCrumbs from 'components/CustomBreadCrumbs';
import { useDocumentsPage } from 'hooks/useDocumentsPage';
import {
    Categories,
    Category,
    Document,
    Documents,
} from 'interfaces/documents';
import { BaseModalRef } from 'modals/BaseModal';
import ConfirmationModal from 'modals/ConfirmationModal';
import DocumentMarkupModal from 'modals/DocumentMarkupModal';
import NewDocumentsTable from 'modules/documents/components/NewDocumentsTable';
import {
    useRef,
    useState
} from 'react';
import {
    useNavigate
} from 'react-router-dom';
import {
    foreverDeleteCategory,
    restoreDeletedCategory
} from 'slices/categoriesActions';
import {
    foreverDeleteDocument,
    foreverDeleteTrashed,
    restoreDeletedDocument
} from 'slices/documentsActions';
import {
    reset
} from 'slices/documentsSlice';
import {
    useAppDispatch,
    useAppSelector
} from 'utilities/hooks';


const DocumentsTrashPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { loading } = useAppSelector((state) => state.documents);
    const confirmationDeleteForeverAllModal = useRef<BaseModalRef>(null);
    const confirmationDeleteForeverModal = useRef<BaseModalRef>(null);
    const confirmationRestoreModal = useRef<BaseModalRef>(null);
    const documentMarkupModal = useRef<BaseModalRef>(null);
    const [selectedDocuments, setSelectedDocuments] = useState<Documents>([]);
    const [selectedCategories, setSelectedCategories] = useState<Categories>([]);

    const {
        fetchData,
        navigateTo,
    } = useDocumentsPage({ isDeleted: true });

    const handleBreadcrumbClick = () => {
        dispatch(reset());
        navigate('/documents/trash');
    };

    const handleConfirmDeleteForeverAll = () => {
        dispatch(foreverDeleteTrashed())
            .unwrap()
            .then(() => {
                fetchData();
            }).catch(() => {});
    };

    const handleConfirmDeleteForever = () => {
        handleDeleteForever();
    };

    const handleDeleteForever = () => {
        let promises: any[] = [];
        selectedCategories.map((category: Category) => {
            promises.push(dispatch(foreverDeleteCategory(category.id)));
        });
        selectedDocuments.map((document: Document) => {
            promises.push(dispatch(foreverDeleteDocument(document.id)));
        });

        Promise.all(promises).then((responses) => {
            fetchData();
        }).catch(() => {});
    };

    const handleConfirmRestore = () => {
        handleRestore();
    };

    const handleRestore = () => {
        let promises: any[] = [];
        selectedCategories.map((category: Category) => {
            promises.push(dispatch(restoreDeletedCategory(category.id)));
        });
        selectedDocuments.map((document: Document) => {
            promises.push(dispatch(restoreDeletedDocument(document.id)));
        });

        Promise.all(promises).then((responses) => {
            fetchData();
        }).catch(() => {});
    };

    return <>
        <Box id="notification-bar"></Box>
        <Grid item xs={12} sx={{py: 3}}>
            <CustomBreadCrumbs
                title={'Deleted files'}
                loading={loading}
                breadcrumbs={[]}
                onRootClick={() => handleBreadcrumbClick()}
            />
        </Grid>

        <NewDocumentsTable deleted />

        <DocumentMarkupModal
            ref={documentMarkupModal}
            readonly
        ></DocumentMarkupModal>
        <ConfirmationModal
            ref={confirmationDeleteForeverAllModal}
            text='Are you sure want to delete forever all items?'
            confirmLabel='Delete Forever'
            onConfirm={handleConfirmDeleteForeverAll}
        ></ConfirmationModal>
        <ConfirmationModal
            ref={confirmationDeleteForeverModal}
            text='Are you sure want to delete forever selected items?'
            confirmLabel='Delete Forever'
            onConfirm={handleConfirmDeleteForever}
        ></ConfirmationModal>
        <ConfirmationModal
            ref={confirmationRestoreModal}
            text='Are you sure want to restore selected items?'
            confirmLabel='Restore'
            onConfirm={handleConfirmRestore}
        ></ConfirmationModal>
    </>;
};

export default DocumentsTrashPage;