import { Flex, Tag, Typography } from "antd";
import { deleteFilter } from "slices/documentsSlice";
import { useAppDispatch, useAppSelector } from "utilities/hooks";
import { TweenOneGroup } from 'rc-tween-one';

const FilterChips = ({
    fetchData,
}: {
    fetchData: () => void,
}) => {
    const dispatch = useAppDispatch();
    const {
        params,
    } = useAppSelector((state) => state.documents);

    const handleDeleteFilter = (key: string, id: any = null) => {
        dispatch(deleteFilter({ key, id }));
        fetchData();
    };

    const renderFilter = (key: string, object: any) => {
        if (object.value === null || object.value === undefined) return;

        if (object.value instanceof Date || typeof object.value == 'string') {
            let date = object.value;
            if (typeof object.value === 'string') date = new Date(object.value);
            return <Tag
                closeIcon
                onClose={(e) => {
                    e.preventDefault();
                    handleDeleteFilter(key);
                }}
                key={key}
            >
                <Typography.Text style={{ fontWeight: 'bold' }} type="secondary">
                    {object.label}:
                </Typography.Text>{' '}
                {date.toISOString()?.slice(0, 10)}
            </Tag>
        } else {
            return object.value.map(function (el: any) {
                if (el.name || el.label) {
                    return <Tag
                        closeIcon
                        onClose={(e) => {
                            e.preventDefault();
                            handleDeleteFilter(key, el.id);
                        }}
                        key={key + (el.id || el.name)}
                    >
                        <Typography.Text style={{ fontWeight: 'bold' }} type="secondary">
                            {object.label}:
                        </Typography.Text>{' '}
                        {(el.name || el.label)}
                    </Tag>
                }
            });
        }
    };

    if (!params.filters) return <></>;
    return (
        <Flex style={{ marginBottom: '10px' }}>
            <TweenOneGroup
                appear={false}
                enter={{ scale: 0.8, opacity: 0, type: 'from', duration: 100 }}
                leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                onEnd={(e) => {
                    if (e.type === 'appear' || e.type === 'enter') {
                        (e.target as any).style = 'display: inline-block';
                    }
                }}
            >
                {Object.keys(params.filters).map((key) => (
                    renderFilter(key, params.filters?.[key])
                ))}
            </TweenOneGroup>
        </Flex>
    );
}

export default FilterChips;
