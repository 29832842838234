import { Grid } from '@mui/material';
import CustomBreadCrumbs from 'components/CustomBreadCrumbs';
import { useDocumentsPage } from 'hooks/useDocumentsPage';
import NewDocumentsTable from '../components/NewDocumentsTable';

const SharedDocumentsPage = () => {
    const {
        currentTeam,
        breadcrumbs,
        loading,
        fetchData,
        navigateTo,
    } = useDocumentsPage({ isShared: true }); // Pass true to indicate shared documents { isShared: true }

    return (
        <>
            <Grid item xs={8} sx={{ pt: 2, pb: 3 }}>
                <CustomBreadCrumbs
                    title={currentTeam ? currentTeam.name : 'Shared Documents'}
                    loading={loading}
                    breadcrumbs={breadcrumbs}
                    onRootClick={() => {
                        navigateTo();
                    }}
                    onClick={(breadcrumb) => {
                        navigateTo(breadcrumb.id);
                    }}
                />
            </Grid>
            <NewDocumentsTable shared />
        </>
    );
};

export default SharedDocumentsPage;
