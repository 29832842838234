import { Select, Tabs, Flex } from 'antd';
import UserAutocomplete from "components/UserAutocomplete";
import { UserAutocompleteRef } from 'components/UserAutocomplete/UserAutocomplete';
import { Invitation } from 'interfaces/invitations';
import { TeamMemberRole, TeamMemberRoleOptions } from "interfaces/teams";
import { User } from 'interfaces/users';
import { useEffect, useRef, useState } from "react";
import { fetchUsersForInvitation } from "slices/invitationsActions";
import { fetchUsers } from "slices/usersActions";
import { useAppDispatch, useAppSelector } from "utilities/hooks";

const SelectTabs = ({
    isLoaded,
    setMembersSelected,
    setInvitationsSelected,
    setCurrentTab,
    excludedMembers,
}: {
    isLoaded: boolean;
    setMembersSelected: (value: User[]) => void;
    setInvitationsSelected: (value: Invitation[]) => void;
    setCurrentTab: (value: string) => void;
    excludedMembers?: string[];
}) => {
    const dispatch = useAppDispatch();
    const userAutocompleteRef = useRef<UserAutocompleteRef>(null);
    const [role, setRole] = useState(TeamMemberRole.VIEWER);
    const loaded = useRef(false);
    const excludedRef = useRef(excludedMembers);

    const {
        loading: teamLoading,
        members: teamMembers,
    } = useAppSelector((state) => state.team);
    const {
        loading: invitationsLoading,
    } = useAppSelector((state) => state.invitations);
    const {
        loading: usersLoading,
    } = useAppSelector((state) => state.users);

    const teamMembersRef = useRef<User[]>(teamMembers || []);

    useEffect(() => {
        loaded.current = isLoaded;
    }, [isLoaded]);

    useEffect(() => {
        excludedRef.current = excludedMembers;
    }, [excludedMembers]);

    useEffect(() => {
        if (teamMembersRef.current.length !== (teamMembers || []).length) {
            userAutocompleteRef.current?.refetch();
        }
        teamMembersRef.current = teamMembers || [];
    }, [teamMembers]);

    const waitForTeamMembers = (): Promise<void> => {
        return new Promise((resolve) => {
            if (loaded.current) {
                resolve();
            } else {
                const interval = setInterval(() => {
                    if (loaded.current) {
                        clearInterval(interval);
                        resolve();
                    }
                }, 100);
                setTimeout(() => {
                    clearInterval(interval);
                    resolve();
                }, 5000);
            }
        });
    };

    const onDebounce = (value: string, callback: (value: any) => void) => {
        waitForTeamMembers().then(() => {
            dispatch(fetchUsers({ search: value, excluded: excludedRef.current }))
                .unwrap()
                .then((response: any) => {
                    callback(response.data);
                }).catch(() => { });
        });
    };

    const onInvitationsDebounce = (value: string, callback: (value: any) => void) => {
        dispatch(fetchUsersForInvitation(value))
            .unwrap()
            .then((response: any) => {
                callback(response.data);
            }).catch(() => { });
    };

    const handleRoleChange = (value: string) => {
        setRole(value as TeamMemberRole);
    };

    const roleSelect = <Select
        defaultValue={role}
        onChange={handleRoleChange}
        popupClassName="ant-picker-dropdown"
        style={{ marginLeft: 10 }}
    >
        {TeamMemberRoleOptions.map((option: any) => {
            return <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>;
        })}
    </Select>;

    const items = [
        {
            key: 'members',
            label: 'Add members',
            children: <Flex style={{ padding: '10px 0' }}>
                <UserAutocomplete
                    label="Add people"
                    onDebounce={onDebounce}
                    loading={teamLoading || usersLoading || !loaded.current}
                    onChange={setMembersSelected}
                    ref={userAutocompleteRef}
                />
                { roleSelect }
            </Flex>,
        },
        {
            key: 'invitations',
            label: 'Send Invitations',
            children: <Flex style={{ padding: '10px 0' }}>
                <UserAutocomplete
                    label="Add by email or phone number"
                    onDebounce={onInvitationsDebounce}
                    loading={invitationsLoading}
                    onChange={setInvitationsSelected}
                />
                { roleSelect }
            </Flex>,
        },
    ];

    return (
        <Tabs
            defaultActiveKey='members'
            items={items}
            onChange={(tabIndex: string) => {
                setCurrentTab(tabIndex);
                if (tabIndex == 'members') userAutocompleteRef.current?.refetch();
            }}
        />
    )
}

export default SelectTabs;
