import { Calendar, CalendarViewType, Gant, ItemTaskType, ScheduleItemType, SmxDrawerPanel } from 'smx-components';
import usePaddingTimeline from '../../../../hooks/usePaddingTimeline';
import { Carousel, Flex } from 'antd';
import ButtonScale from './buttonScale';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { SchedulePageViewType, ScheduleViewType } from '../../../../types';
import { useEffect, useMemo, useRef } from 'react';
import ButtonAddTask from './buttonAddTask';
import { useAppDispatch, useAppSelector } from '../../../../utilities/hooks';
import { setterSchedule } from '../../../../slices/scheduleSlice';
import ControlEditMode from './controlEditMode';

const SchedulePage = () => {
    const dispatch = useAppDispatch();
    const paddingBottom = usePaddingTimeline();
    const activeCalendarPage = useSelector<RootState, CalendarViewType>((state) => state.schedule.activeCalendarPage);
    const schedulePageView = useSelector<RootState, SchedulePageViewType | undefined>(
        (state) => state.schedule.schedulePageView,
    );
    const triggerForNewItem = useSelector<RootState, ScheduleItemType | undefined>(
        (state) => state.schedule.triggerForNewItem,
    );
    const triggerForNewTask = useSelector<RootState, ItemTaskType | undefined>(
        (state) => state.schedule.triggerForNewTask,
    );
    const user = useAppSelector((state) => state.account.user);

    const view = useSelector<RootState, ScheduleViewType>((state) => state.schedule.view);
    const carouselRef = useRef<any>();

    const gant = useMemo(
        () => (
            <Gant paddingBottom={paddingBottom} newItem={triggerForNewItem} mode={'chart'} authorId={user?.id || ''} />
        ),
        [triggerForNewItem, user],
    );
    const gantTable = useMemo(
        () => <Gant paddingBottom={paddingBottom} mode={'table'} authorId={user?.id || ''} />,
        [user],
    );
    const calendarDay = useMemo(
        () => <Calendar paddingBottom={paddingBottom} activePage={'day'} newTask={triggerForNewTask} />,
        [triggerForNewTask],
    );
    const calendarAgenda = useMemo(() => <Calendar paddingBottom={paddingBottom} activePage={'agenda'} />, []);
    const calendarTable = useMemo(() => <Calendar paddingBottom={paddingBottom} activePage={'table'} />, []);

    useEffect(() => {
        if (triggerForNewItem) {
            dispatch(setterSchedule({ triggerForNewItem: undefined }));
        }
    }, [triggerForNewItem]);

    useEffect(() => {
        if (triggerForNewTask) {
            dispatch(setterSchedule({ triggerForNewTask: undefined }));
        }
    }, [triggerForNewTask]);

    const getNumberSlide = () => {
        if (view === 'gant') return 0;
        if (view === 'calendar' && activeCalendarPage === 'table') return 1;
        if (view === 'calendar' && activeCalendarPage === 'agenda') return 2;
        if (view === 'calendar' && activeCalendarPage === 'day') return 3;
        return 0;
    };

    useEffect(() => {
        const newCurrentSlide = getNumberSlide();
        carouselRef.current.goTo(newCurrentSlide);
    }, [activeCalendarPage, view]);

    return (
        <Flex vertical>
            <Flex justify={'space-between'}>
                <ControlEditMode />

                <ButtonScale />
            </Flex>
            <Carousel ref={carouselRef} dots={false} lazyLoad={'progressive'} effect="fade">
                <div>{view === 'gant' && gant}</div>
                <div>
                    <div style={{ marginTop: 10 }}>
                        {schedulePageView === 'listTask'
                            ? calendarTable
                            : view === 'calendar' && activeCalendarPage === 'table' && gantTable}
                    </div>
                </div>
                <div>{view === 'calendar' && activeCalendarPage === 'agenda' && calendarAgenda}</div>
                <div>{view === 'calendar' && activeCalendarPage === 'day' && calendarDay}</div>
            </Carousel>
            <div style={{ position: 'absolute', left: 10000 }}>
                <div>{(activeCalendarPage === 'table' || view === 'calendar') && gant}</div>
                <div>{(activeCalendarPage === 'table' || view === 'gant') && calendarDay}</div>
            </div>
            <SmxDrawerPanel />
        </Flex>
    );
};

export default SchedulePage;
