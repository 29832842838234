import { Button, Dropdown, Space } from "antd";
import { ArrowRightOutlined, CopyOutlined, DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { ItemType } from "antd/es/menu/interface";
import { useEffect, useRef } from "react";

import { Category, Document } from "interfaces/documents";
import { BaseModalRef } from "modals/BaseModal";
import CategoriesTreeModal from "modals/CategoriesTreeModal";
import ConfirmationModal from "modals/ConfirmationModal";
import { moveCategory, softDeleteCategory } from "slices/categoriesActions";
import { setExcluded } from "slices/categoriesSlice";
import { copyDocument, moveDocument, softDeleteDocument } from "slices/documentsActions";
import { useAppDispatch } from "utilities/hooks";

const ActionDropdown = ({
    fetchData,
    selected,
}: {
    fetchData: () => void,
    selected: any[],
}) => {
    const dispatch = useAppDispatch();
    const confirmBulkDeleteModal = useRef<BaseModalRef>(null);
    const copyBulkModal = useRef<BaseModalRef>(null);
    const moveBulkModal = useRef<BaseModalRef>(null);

    const actions: ItemType[] = [
        {
            key: 'Delete',
            label: 'Delete',
            onClick: () => {
                confirmBulkDeleteModal?.current?.open();
            },
            icon: <DeleteOutlined />,
            disabled: !selected.length,
        },
        {
            key: 'Copy',
            label: 'Copy',
            onClick: () => {
                copyBulkModal.current?.open();
            },
            icon: <CopyOutlined />,
            disabled: !selected.length || !!selected.filter((item) => item.type === 'category').length,
        },
        {
            key: 'Move',
            label: 'Move',
            onClick: () => openBulkMoveModal(),
            icon: <ArrowRightOutlined />,
            disabled: !selected.length,
        },
    ];

    const bulkDelete = (hasConfirmed: Boolean) => {
        if (!hasConfirmed) return;

        let promises: any[] = [];
        selected.forEach((item: any) => {
            if (item.type === 'category') {
                promises.push(dispatch(softDeleteCategory(item.id)));
            } else {
                promises.push(dispatch(softDeleteDocument(item.id)));
            }
        });

        Promise.all(promises).then(() => {
            fetchData();
        }).catch(() => { });
    };

    const bulkCopy = (destination: Category | null) => {
        let promises: any[] = [];

        selected.map((document: Document) => {
            promises.push(dispatch(copyDocument({
                id: document.id,
                category_id: destination?.id || null,
            })));
        });

        Promise.all(promises).then((responses) => {
            fetchData();
        }).catch(() => { });
    };

    const openBulkMoveModal = () => {
        let categories = selected.filter((item: any) => item.type === 'category');
        dispatch(setExcluded(categories));
        moveBulkModal.current?.open();
    };

    const bulkMove = (destination: Category | null) => {
        let promises: any[] = [];
        selected.forEach((item: any) => {
            if (item.type === 'category') {
                promises.push(dispatch(moveCategory({
                    id: item.id,
                    parent_id: destination?.id || null,
                })));
            } else {
                promises.push(dispatch(moveDocument({
                    id: item.id,
                    category_id: destination?.id || null,
                })));
            }
        });

        Promise.all(promises).then(() => {
            fetchData();
        }).catch(() => { });
    };

    return (
        <>
            <Dropdown menu={{ items: actions }}>
                <Button>
                    <Space>
                        Actions
                        <DownOutlined />
                    </Space>
                </Button>
            </Dropdown>


            <CategoriesTreeModal
                ref={moveBulkModal}
                title={`Move ${selected.length} item(s) to...`}
                submitLabel='Move'
                onSubmit={bulkMove}
            ></CategoriesTreeModal>
            <CategoriesTreeModal
                ref={copyBulkModal}
                title={`Copy ${selected.length} item(s) to...`}
                submitLabel='Copy'
                onSubmit={bulkCopy}
            ></CategoriesTreeModal>
            <ConfirmationModal
                ref={confirmBulkDeleteModal}
                text='Are you sure want to delete selected items?'
                onConfirm={bulkDelete}
            ></ConfirmationModal>
        </>
    )
}

export default ActionDropdown;
