import React from 'react';
import ButtonAddScheduleItem from './buttonAddScheduleItem';
import { ConfigProvider, Flex } from 'antd';
import ButtonAddTask from './buttonAddTask';

const ControlEditMode = () => {
    return (
        <Flex gap={10}>
            <ConfigProvider
                theme={{
                    token: {
                        borderRadius: 50,
                    },
                }}
            >
                <ButtonAddScheduleItem />
                <ButtonAddTask />
            </ConfigProvider>
        </Flex>
    );
};

export default ControlEditMode;
