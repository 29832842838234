import { Button, Flex } from "antd";
import { useRef } from "react";

import { User } from "interfaces/users";
import { BaseModalRef } from "modals/BaseModal";
import ConfirmationModal from "modals/ConfirmationModal";
import { addSnackbar, SnackbarTypes } from "slices/snackbarsSlice";
import { createGuestTeam, softDeleteGuestTeam, updateGuestTeamMembers } from "slices/teamsActions";
import { useAppDispatch, useAppSelector } from "utilities/hooks";
import { Invitation } from "interfaces/invitations";

const Actions = ({
    modal,
    onSubmit,
    role,
    membersSelected = [],
    invitationsSelected = [],
    entity,
    currentTab,
}: {
    modal: React.RefObject<BaseModalRef>,
    onSubmit: () => void,
    role: string,
    membersSelected?: any[],
    invitationsSelected?: any[],
    entity: any,
    currentTab: string,
}) => {
    const dispatch = useAppDispatch();
    const entityId = entity?.type === 'category' ? entity?.uuid : entity?.id;
    const {
        loading: teamLoading,
        team: guestTeam,
        members: teamMembers,
        membersMap: teamMembersMap
    } = useAppSelector((state) => state.team);
    const {
        loading: invitationsLoading,
        invitations
    } = useAppSelector((state) => state.invitations);

    const confirmRemoveAccessModal = useRef<BaseModalRef>(null);

    const handleRemoveAccess = () => {
        confirmRemoveAccessModal.current?.open();
    };

    const handleConfirmRemoveAccess = () => {
        dispatch(softDeleteGuestTeam(entityId))
            .unwrap()
            .then(() => {
                onSubmit();
                close();
            }).catch(() => { });
    };

    const handleSave = () => {
        let promise: any = null;
        let data: any = {
            members: teamMembers || [],
            invitations: invitations || [],
        };

        if (teamMembers?.length || invitations?.length) {
            promise = updateGuestTeamMembers;
            data.entityId = entityId;
        } else {
            promise = createGuestTeam;
            data.entity = {
                id: entityId,
                type: entity?.type,
            };
        }

        if (currentTab === 'members') {
            if (membersSelected.length === 0) {
                dispatch(addSnackbar({
                    type: SnackbarTypes.ERROR,
                    message: 'Please, add people to share.'
                }));
                return;
            }

            data.members = data.members.concat(membersSelected);
        } else if (currentTab === 'invitations') {
            if (invitationsSelected.length === 0) {
                dispatch(addSnackbar({
                    type: SnackbarTypes.ERROR,
                    message: 'Please, add contacts for invitation.'
                }));
                return;
            }

            data.invitations = data.invitations.concat(invitationsSelected);
        }
        data.members = data.members.map((member: User & { value?: string }) => {
            return {
                id: member.value || member.id,
                role: role,
            };
        });
        data.invitations = data.invitations.map((invitation: Invitation) => {
            return {
                id: invitation.id,
                email: invitation.email,
                phone: invitation.phone,
                team_role: role,
            };
        });
        dispatch(promise(data)).unwrap().then(() => {
            onSubmit();
            close();
        });
    };

    const close = () => {
        modal?.current?.close();
    };

    return (
        <>
            <Flex style={{ width: "100%" }}>
                {!!(teamMembers?.length || invitations?.length) && (
                    <Button
                        color="danger"
                        variant="outlined"
                        onClick={handleRemoveAccess}
                        loading={teamLoading || invitationsLoading}
                    >
                        Stop Sharing
                    </Button>
                )}
                <div style={{ marginLeft: 'auto' }}>
                    <Button type="text" onClick={close}>
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        onClick={handleSave}
                        loading={teamLoading || invitationsLoading}
                    >
                        Save
                    </Button>
                </div>
            </Flex>

            <ConfirmationModal
                ref={confirmRemoveAccessModal}
                text='Are you sure want to stop sharing?'
                onConfirm={handleConfirmRemoveAccess}
            ></ConfirmationModal>
        </>
    )
};

export default Actions;
