import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Modal } from 'antd';
import { useAppDispatch } from '../../../../utilities/hooks';
import { setterSchedule } from '../../../../slices/scheduleSlice';
import { DateTime } from 'luxon';
import {
    COLOR_ITEM,
    FORMAT_DATE_TIME,
    ScheduleItemType,
    FORMAT_DATE_FOR_SAVE,
    FieldProject,
    FieldStartDate,
    FieldEndDate,
    FieldPhase,
} from 'smx-components';
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';

type NewItemType = {
    projectId: string;
    phaseId: number;
    startDate: string;
    endDate: string;
};

const ButtonAddScheduleItem = () => {
    const dispatch = useAppDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm<NewItemType>();
    const refListProject = useRef<React.FC<any>>(null);

    const values = Form.useWatch([], form);

    useEffect(() => {
        form.setFieldsValue({
            startDate: dayjs(DateTime.now().toFormat(FORMAT_DATE_TIME)) as any,
            endDate: dayjs(DateTime.now().plus({ days: 3 }).toFormat(FORMAT_DATE_TIME)) as any,
        });
    }, [isModalOpen]);

    const onCLickAdd = () => {
        setIsModalOpen(true);
    };

    const addNewItem = (values: NewItemType) => {
        const newItem = {
            color: COLOR_ITEM,
            start_datetime: DateTime.fromISO((values?.startDate as any).format(FORMAT_DATE_FOR_SAVE)).toFormat(
                FORMAT_DATE_TIME,
            ),
            end_datetime: DateTime.fromISO((values?.endDate as any).format(FORMAT_DATE_FOR_SAVE)).toFormat(
                FORMAT_DATE_TIME,
            ),
            project_id: values.projectId,
            phase_id: values.phaseId,
            completion_percentage: 0,
        } as ScheduleItemType;

        dispatch(setterSchedule({ triggerForNewItem: newItem }));
    };

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            addNewItem(values);
            setIsModalOpen(false);
        } catch (errorInfo) {
            console.log('Validation error:', errorInfo);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button type={'primary'} onClick={onCLickAdd} icon={<PlusOutlined />}>
                Add Item
            </Button>
            <Modal
                title="Add new schedule item"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                zIndex={2000}
                width={300}
            >
                <Form
                    name="newScheduleItemFrom"
                    layout="vertical"
                    form={form}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                >
                    <FieldProject name={'projectId'} ref={refListProject as any} required />
                    <FieldPhase
                        name={'phaseId'}
                        parentRef={refListProject as any}
                        required
                        disabled={!values?.['projectId']}
                    />

                    <FieldStartDate required />
                    <FieldEndDate required />
                </Form>
            </Modal>
        </>
    );
};

export default ButtonAddScheduleItem;
