import {
    Power,
    PowerOff,
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    Container,
    Stack,
    Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchIntegrations, revokeIntegration } from 'slices/integrationsActions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

const IntegrationsPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { currentTenant } = useAppSelector((state) => state.tenants);
    const { accessToken } = useAppSelector((state) => state.auth);
    const { integrations, loading } = useAppSelector((state) => state.integrations);
    const [hasActiveIntegration, setHasActiveIntegration] = useState(false);

    const handleConnect = (integration: any) => {
        const popup = window.open(integration.url, '', 'width=600,height=800');
        if (popup) {
            const checkWindow = setInterval(() => {
                if (popup.closed) {
                    dispatch(fetchIntegrations());
                    clearInterval(checkWindow);
                }
            }, 500);
        }
    };

    const handleRevoke = (integration: any) => {
        dispatch(revokeIntegration(integration.id))
            .then(() => {
                dispatch(fetchIntegrations());
            }).catch();
    };

    useEffect(() => {
        if (!currentTenant) return;

        dispatch(fetchIntegrations());
    }, [currentTenant]);

    useEffect(() => {
        if (!integrations) return;

        const activeIntegration = integrations.find((integration: any) => (integration.status == 'active' || integration.status == 'processing'));
        setHasActiveIntegration(!!activeIntegration);
    }, [integrations]);

    return <>
        <Container component="main" maxWidth="xs">
            <Stack spacing={5} sx={{ mt: 3 }}>
                <Box>
                    <Stack
                        direction="column"
                        spacing={1}
                        alignItems="center"
                        justifyContent="center"
                        sx={{ mb: 3 }}
                    >
                        <Typography variant="h5">Integrations</Typography>
                        {integrations && integrations.map((integration: any, index: number) => {
                            return <>
                                {integration.status != 'inactive' && <LoadingButton
                                    key={index}
                                    variant="outlined"
                                    size="large"
                                    disableElevation
                                    sx={{ mb: 3 }}
                                    loading={loading}
                                    fullWidth
                                    onClick={() => {handleRevoke(integration)}}
                                    endIcon={<Power />}
                                >
                                    {integration.title}
                                </LoadingButton>}
                                {integration.status == 'inactive' && <LoadingButton
                                    key={index}
                                    size="large"
                                    disableElevation
                                    sx={{ mb: 3 }}
                                    loading={loading}
                                    fullWidth
                                    onClick={() => {handleConnect(integration)}}
                                    endIcon={<PowerOff />}
                                    disabled={hasActiveIntegration}
                                >
                                    {integration.title}
                                </LoadingButton>}
                            </>;
                        })}
                    </Stack>
                </Box>
            </Stack>
        </Container>
    </>;
};

export default IntegrationsPage;