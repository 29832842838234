import React, { useEffect, useRef, useState } from 'react';
import { Button, DatePicker, Form, Modal, TimePicker } from 'antd';
import {
    COLOR_ITEM,
    FieldPhase,
    FieldProject,
    FORMAT_DATE,
    FORMAT_DATE_DATEPICKER,
    FORMAT_DATE_FOR_SAVE,
    FORMAT_TIME,
    ItemTaskType,
} from 'smx-components';
import { setterSchedule } from '../../../../slices/scheduleSlice';
import { useAppDispatch } from '../../../../utilities/hooks';
import { DateTime } from 'luxon';
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';

type NewItemTaskType = {
    project_id: string;
    phase_id: number;
    datetime: string;
    start_datetime: string;
    end_datetime: string;
};

const ButtonAddTask = () => {
    const dispatch = useAppDispatch();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm<NewItemTaskType>();
    const refListProject = useRef<React.FC<any>>(null);

    const values = Form.useWatch([], form);

    useEffect(() => {
        if (values && !values?.project_id) {
            form.setFieldsValue({
                phase_id: undefined,
            });
        }
    }, [values?.project_id]);

    useEffect(() => {
        form.setFieldsValue({
            datetime: dayjs(DateTime.now().toFormat(FORMAT_DATE)) as any,
            start_datetime: dayjs(DateTime.now().toFormat(FORMAT_TIME), 'HH:mm') as any,
            end_datetime: dayjs(DateTime.now().plus({ hour: 1 }).toFormat(FORMAT_TIME), 'HH:mm') as any,
        });
    }, [isModalOpen]);

    const onCLickAdd = () => {
        setIsModalOpen(true);
    };

    const addNewTask = (values: NewItemTaskType) => {
        const newTask = {
            color: COLOR_ITEM,
            project_id: values.project_id,
            phase_id: +values.phase_id,
            datetime: DateTime.fromISO((values?.datetime as any).format(FORMAT_DATE_FOR_SAVE)).toFormat(FORMAT_DATE),
            end_datetime: (values?.end_datetime as any).format(FORMAT_TIME),
            name: 'new task',
            start_datetime: (values?.start_datetime as any).format(FORMAT_TIME),
            subtask: '[]',
        } as ItemTaskType;

        dispatch(setterSchedule({ triggerForNewTask: newTask }));
    };

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            addNewTask(values);
            setIsModalOpen(false);
        } catch (errorInfo) {
            console.log('Validation error:', errorInfo);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button type={'primary'} onClick={onCLickAdd} icon={<PlusOutlined />}>
                Add Task
            </Button>

            <Modal
                title="Add new task"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                zIndex={2000}
                width={300}
            >
                <Form
                    name="newTaskFrom"
                    layout="vertical"
                    form={form}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                >
                    {
                        // @ts-ignore
                        <FieldProject<ItemTaskType> name={'project_id'} ref={refListProject as any} />
                    }
                    <FieldPhase<ItemTaskType>
                        name={'phase_id'}
                        parentRef={refListProject as any}
                        disabled={!values?.['project_id']}
                    />

                    <Form.Item<ItemTaskType>
                        label="Date"
                        name="datetime"
                        rules={[{ required: true, message: 'Please input date!' }]}
                    >
                        <DatePicker style={{ width: '100%' }} format={FORMAT_DATE_DATEPICKER} />
                    </Form.Item>
                    <Form.Item<ItemTaskType>
                        label="Start Time"
                        name="start_datetime"
                        rules={[{ required: true, message: 'Please input time!' }]}
                    >
                        <TimePicker minuteStep={10} style={{ width: '100%' }} format={'h:mm A'} />
                    </Form.Item>
                    <Form.Item<ItemTaskType>
                        label="End Time"
                        name="end_datetime"
                        rules={[{ required: true, message: 'Please input time!' }]}
                    >
                        <TimePicker minuteStep={10} style={{ width: '100%' }} format={'h:mm A'} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ButtonAddTask;
