import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import {
    fetchCategories,
    moveCategory,
    softDeleteCategory,
} from 'slices/categoriesActions';
import { setExcluded } from 'slices/categoriesSlice';
import { fetchDeletedDocuments, fetchDocuments, fetchSharedDocuments } from 'slices/documentsActions';
import { syncIntegration } from 'slices/integrationsActions';
import { softDeleteTeam } from 'slices/teamsActions';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { BaseModalRef } from 'modals/BaseModal';
import { Category } from 'interfaces/documents';
import { Team } from 'interfaces/teams';
import { reset } from 'slices/documentsSlice';

export const useDocumentsPage = ({ isShared = false, isDeleted = false }: { isShared?: boolean, isDeleted?: boolean } = {}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { categoryId: categoryIdString } = useParams<{ categoryId?: string }>();
    const categoryId = categoryIdString ? parseInt(categoryIdString) : null;

    const { currentTeam } = useAppSelector((state) => state.teams);
    const { integrations } = useAppSelector((state) => state.integrations);
    const { list, tree, teams, breadcrumbs, loading } = useAppSelector((state) => state.documents);

    const [currentBreadcrumb, setCurrentBreadcrumb] = useState<Category | null>(null);
    const [currentEntity, setCurrentEntity] = useState<any | null>(null);

    const teamCreateModal = useRef<BaseModalRef>(null);
    const teamMembersUpdateModal = useRef<BaseModalRef>(null);
    const teamRenameModal = useRef<BaseModalRef>(null);
    const confirmTeamDeleteModal = useRef<BaseModalRef>(null);
    const confirmDeleteBreadcrumbModal = useRef<BaseModalRef>(null);
    const guestTeamCreateModal = useRef<BaseModalRef>(null);
    const guestTeamMembersUpdateModal = useRef<BaseModalRef>(null);
    const moveRowModal = useRef<BaseModalRef>(null);

    const breadcrumbPopupState = usePopupState({ variant: 'popover', popupId: 'itemsMenu' });
    const rootBreadcrumbPopupState = usePopupState({ variant: 'popover', popupId: 'teamsMenu' });

    const fetch = isShared ? fetchSharedDocuments : isDeleted ? fetchDeletedDocuments : fetchDocuments;

    const fetchData = () => {
        dispatch(fetch(categoryId));
    };

    const handleSyncIntegration = (integration: any) => {
        dispatch(syncIntegration(integration.id));
    };

    const handleConfirmTeamDelete = () => {
        if (!currentTeam) return;
        dispatch(softDeleteTeam(currentTeam.id))
            .unwrap()
            .then(() => {
                navigate('/documents');
            })
            .catch(() => {});
    };

    const openGuestTeamModal = (item: any) => {
        let team: Team | null = null;
        if (item.type === 'category') {
            team = teams[item?.uuid];
        } else {
            team = teams[item?.id];
        }
        setCurrentEntity(item);

        if (team) {
            guestTeamMembersUpdateModal.current?.open();
        } else {
            guestTeamCreateModal.current?.open();
        }
    };

    const openMoveModal = () => {
        if (!currentBreadcrumb) return;
        dispatch(setExcluded([currentBreadcrumb]));
        moveRowModal.current?.open();
    };

    const moveCurrentBreadcrumb = (destination: Category | null) => {
        if (!currentBreadcrumb) return;
        dispatch(moveCategory({
            id: currentBreadcrumb.id.toString(),
            parent_id: destination?.id || null,
        }))
            .then(() => {
                dispatch(fetch(currentBreadcrumb.id));
            })
            .catch(() => {});
    };

    const handleConfirmBreadcrumbDelete = () => {
        if (!currentBreadcrumb) return;
        const nextBreadcrumb = breadcrumbs.length > 1 ? breadcrumbs[breadcrumbs.length - 2] : null;
        dispatch(softDeleteCategory(currentBreadcrumb.id))
            .unwrap()
            .then(() => {
                navigateTo(nextBreadcrumb?.id);
                dispatch(fetch(nextBreadcrumb?.id || null));
            })
            .catch(() => {});
    };

    const navigateTo = (categoryId?: number | null, documentId?: string) => {
        // dispatch(reset());
        let to = isShared ? '/documents/shared' : '/documents';
        if (categoryId) to += `/${categoryId}`;
        navigate(to);
    };

    return {
        categoryId,
        currentTeam,
        integrations,
        list,
        tree,
        teams,
        breadcrumbs,
        loading,
        currentBreadcrumb,
        setCurrentBreadcrumb,
        currentEntity,
        setCurrentEntity,
        teamCreateModal,
        teamMembersUpdateModal,
        teamRenameModal,
        confirmTeamDeleteModal,
        confirmDeleteBreadcrumbModal,
        guestTeamCreateModal,
        guestTeamMembersUpdateModal,
        moveRowModal,
        breadcrumbPopupState,
        rootBreadcrumbPopupState,
        fetchData,
        handleSyncIntegration,
        handleConfirmTeamDelete,
        openGuestTeamModal,
        openMoveModal,
        moveCurrentBreadcrumb,
        handleConfirmBreadcrumbDelete,
        navigateTo,
    };
};