import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tenant, Tenants } from '../interfaces/tenants';
import { getSubdomain, getTenant } from '../utilities/axios';
import { login, loginOTACode } from './authActions';
import { createTenant, fetchTenants } from './tenantsActions';

const subdomain = getSubdomain();
const tenantName = getTenant();
// React was built to support multiple tenants, but the current implementation only supports one tenant.
const currentTenantDomain = window.location.hostname;

export interface TenantsState {
    resolved: boolean;
    error: any | null;
    loading: boolean;
    tenants: Tenants | null;
    currentTenant: Tenant | null;
    currentTenantDomain: string | null;
    defaultTenantDomain: string | null;
    defaultTenantName: string | null;
};

export const initialState: TenantsState = {
    loading: false,
    error: null,
    resolved: false,
    tenants: [],
    currentTenant: {
        id: '',
        name: tenantName || '',
        title: '',
        domain: currentTenantDomain || '',
        paragon_token: null,
        paragon_project_id: null,
    },
    currentTenantDomain: currentTenantDomain,
    defaultTenantDomain: subdomain,
    defaultTenantName: tenantName
};

const _resolveCurrentTenant = (state: TenantsState, tenants: Tenants) => {
    if (state.resolved) return;
    state.resolved = true;

    if (state.defaultTenantName) {
        state.currentTenant = tenants.find((tenant: Tenant) => tenant.name === state.defaultTenantName) as Tenant;
        if (state.currentTenant) {
            state.currentTenantDomain = state.currentTenant.domain;
        }
    } else if (state.defaultTenantDomain) {
        state.currentTenant = tenants.find((tenant: Tenant) => tenant.domain === state.defaultTenantDomain) as Tenant;
        if (state.currentTenant) {
            state.currentTenantDomain = state.currentTenant.domain;
        }
    } else {
        state.currentTenant = tenants[0];
        state.currentTenantDomain = tenants[0]?.domain;
    }

    localStorage.setItem('currentTenantDomain', state.currentTenant.domain);
};

const _setCurrentTenant = (state: TenantsState, tenant: Tenant) => {
    state.currentTenant = tenant;
    state.currentTenantDomain = tenant.domain;
    if (tenant.domains && tenant.domains.indexOf(currentTenantDomain) >= 0) {
        tenant.domain = currentTenantDomain;
        state.currentTenantDomain = currentTenantDomain;
    }
    localStorage.setItem('currentTenant', state.currentTenant.name);
};

const tenantsSlice = createSlice({
    name: 'tenants',
    initialState,
    reducers: {
        reset: () => initialState,
        resolveCurrentTenant: (state, action: PayloadAction<Tenants>) => {
            _resolveCurrentTenant(state, action.payload);
        },
        setCurrentTenant: (state, action: PayloadAction<Tenant>) => {
            _setCurrentTenant(state, action.payload);
        },
        resetCurrentTenant: (state) => {
            state.currentTenant = null;
            state.currentTenantDomain = null;
            localStorage.removeItem('currentTenant');
        },
    },
    extraReducers: builder => {
        builder.addCase(createTenant.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(createTenant.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;

            if (!action.payload) return;
            if (!action.payload.data) return;
        })
        builder.addCase(createTenant.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.tenants = [];
            state.error = action.payload.data;
        })

        builder.addCase(fetchTenants.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchTenants.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.tenants = action.payload.data;
            _resolveCurrentTenant(state, action.payload.data);
        })
        builder.addCase(fetchTenants.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.tenants = [];
            state.error = action.payload.data;
        })

        builder.addCase(login.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;

            if (!action.payload) return;
            if (!action.payload.data) return;
            state.tenants = action.payload.data.tenants;

            if (!state.tenants) return;
            if (!state.tenants.length) return;
            _resolveCurrentTenant(state, action.payload.data.tenants);
        })
        builder.addCase(login.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.tenants = [];
            state.error = action.payload.data;
        })

        builder.addCase(loginOTACode.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;

            if (!action.payload) return;
            if (!action.payload.data) return;
            state.tenants = action.payload.data.tenants;

            if (!state.tenants) return;
            if (!state.tenants.length) return;
            _resolveCurrentTenant(state, action.payload.data.tenants);
        })
    },
});

export const {
    reset,
    resetCurrentTenant ,
    resolveCurrentTenant,
    setCurrentTenant,
} = tenantsSlice.actions;
export default tenantsSlice.reducer;