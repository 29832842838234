import React, { useEffect } from 'react';
import { Dropdown, MenuProps, Radio, RadioChangeEvent } from 'antd';
import { useAppDispatch } from '../../../../utilities/hooks';
import { setterSchedule } from '../../../../slices/scheduleSlice';
import { SchedulePageViewType, ScheduleViewType } from '../../../../types';
import { CalendarViewType } from 'smx-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

const ButtonScale = () => {
    const dispatch = useAppDispatch();
    const schedulePageView = useSelector<RootState, SchedulePageViewType | undefined>(
        (state) => state.schedule.schedulePageView,
    );

    const onChangeScale = ({ target: { value } }: RadioChangeEvent) => {
        dispatch(setterSchedule({ schedulePageView: value }));
    };

    useEffect(() => {
        let view: ScheduleViewType = 'calendar';
        let activeCalendarPage: CalendarViewType = 'day';

        if (schedulePageView === 'timeline') {
            view = 'gant';
        }

        if (schedulePageView === 'schedule') {
            activeCalendarPage = 'day';
        }

        if (schedulePageView === 'board') {
            activeCalendarPage = 'agenda';
        }

        if (['listTask', 'listItem', 'list', 'table'].includes(schedulePageView || '')) {
            activeCalendarPage = 'table';
        }

        console.log('*(*(*(*(*(*(', schedulePageView);

        dispatch(setterSchedule({ activeCalendarPage, view }));
    }, [schedulePageView]);

    const items: MenuProps['items'] = [
        {
            key: '2',
            label: 'Schedule Item',
            onClick: () => {
                dispatch(setterSchedule({ schedulePageView: 'listItem' }));
            },
        },
        {
            key: '1',
            label: 'Task',
            onClick: () => {
                dispatch(setterSchedule({ schedulePageView: 'listTask' }));
            },
        },
    ];

    const getValue = () => {
        if (!schedulePageView) {
            return 'timeline';
        }

        return ['listTask', 'listItem'].includes(schedulePageView) ? 'list' : schedulePageView;
    };

    return (
        <Radio.Group value={getValue()} optionType="button">
            <Dropdown menu={{ items }} placement="bottomLeft">
                <Radio.Button value={'list'}>List</Radio.Button>
            </Dropdown>
            <Radio.Button value={'timeline'} onChange={onChangeScale}>
                Timeline
            </Radio.Button>
            <Radio.Button value={'schedule'} onChange={onChangeScale}>
                Schedule
            </Radio.Button>
            <Radio.Button value={'board'} onChange={onChangeScale} disabled>
                Board
            </Radio.Button>
        </Radio.Group>
    );
};

export default ButtonScale;
