import {
    AnyAction,
    createListenerMiddleware,
    createSlice,
    isFulfilled,
    isRejected,
    nanoid
} from '@reduxjs/toolkit';
import {
    closeSnackbar,
    enqueueSnackbar
} from 'notistack';
import {
    removeAccessAndReset
} from 'slices/authSlice';
import RESPONSE_STATUSES from 'utilities/http-statuses';

export enum SnackbarTypes {
    DEFAULT = 'default',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    SUCCESS = 'success',
};

export interface SnackbarItem {
    id: string;
    type: SnackbarTypes;
    message: string;
    isOpen: boolean;
};

export interface SnackbarItems extends Array<SnackbarItem> { };

interface SnackbarItemsState {
    items: SnackbarItems;
};

export const initialState: SnackbarItemsState = {
    items: [],
};

const _make = (payload: any) => {
    return {
        id: payload.id || nanoid(),
        type: payload.type || SnackbarTypes.ERROR,
        message: payload.message,
        isOpen: true,
    };
};

const _add = (state: any, payload: any) => {
    if (!enqueueSnackbar) return;

    const id = enqueueSnackbar(payload.message, {
        variant: payload.type,
    });

    const error: SnackbarItem = _make({
        id: id,
        ...payload
    });

    state.items.push(error);
};

const _remove = (state: any, payload: any) => {
    if (!state.items) return;

    let errors = state.items.filter((item: any) => item.id !== payload);

    closeSnackbar(payload);

    state.items = errors;
};

const snackbarsSlice = createSlice({
    name: 'snackbars',
    initialState: initialState,
    reducers: {
        reset: () => initialState,
        addSnackbar: (state, action) => {
            _add(state, action.payload);
        },
        removeSnackbar: (state, action) => {
            _remove(state, action.payload);
        },
    },
    extraReducers: builder => {}
});

export const handleRejectedMiddleware = createListenerMiddleware();
export const handleFulfilledMiddleware = createListenerMiddleware();

handleRejectedMiddleware.startListening({
    predicate: (action: AnyAction) => {
        return isRejected(action);
    },
    effect: (action: AnyAction, { dispatch }) => {
        switch (action.payload?.status) {
            case RESPONSE_STATUSES.OK:
            case RESPONSE_STATUSES.CREATED:
                const message = action.payload?.message;
                if (message) {
                    dispatch(snackbarsSlice.actions.addSnackbar({
                        type: SnackbarTypes.SUCCESS,
                        message: message,
                    }));
                }
                break;
            case RESPONSE_STATUSES.UNAUTHORIZED:
                dispatch(snackbarsSlice.actions.addSnackbar(action.payload.data));
                dispatch(removeAccessAndReset());
                break;
            case RESPONSE_STATUSES.BAD_REQUEST:
            case RESPONSE_STATUSES.PAYMENT_REQUIRED:
            case RESPONSE_STATUSES.FORBIDDEN:
            case RESPONSE_STATUSES.NOT_FOUND:
            case RESPONSE_STATUSES.METHOD_NOT_ALLOWED:
            case RESPONSE_STATUSES.NOT_ACCEPTABLE:
            case RESPONSE_STATUSES.PROXY_AUTHENTICATION_REQUIRED:
            case RESPONSE_STATUSES.REQUEST_TIMEOUT:
            case RESPONSE_STATUSES.CONFLICT:
            case RESPONSE_STATUSES.GONE:
            case RESPONSE_STATUSES.LENGTH_REQUIRED:
            case RESPONSE_STATUSES.PRECONDITION_FAILED:
            case RESPONSE_STATUSES.PAYLOAD_TOO_LARGE:
            case RESPONSE_STATUSES.URI_TOO_LONG:
            case RESPONSE_STATUSES.UNSUPPORTED_MEDIA_TYPE:
            case RESPONSE_STATUSES.RANGE_NOT_SATISFIABLE:
            case RESPONSE_STATUSES.EXPECTATION_FAILED:
            case RESPONSE_STATUSES.MISDIRECTED_REQUEST:
            case RESPONSE_STATUSES.UNPROCESSABLE_ENTITY:
            case RESPONSE_STATUSES.LOCKED:
            case RESPONSE_STATUSES.FAILED_DEPENDENCY:
            case RESPONSE_STATUSES.UPGRADE_REQUIRED:
            case RESPONSE_STATUSES.PRECONDITION_REQUIRED:
            case RESPONSE_STATUSES.TOO_MANY_REQUESTS:
            case RESPONSE_STATUSES.REQUEST_HEADER_FIELDS_TOO_LARGE:
            case RESPONSE_STATUSES.UNAVAILABLE_FOR_LEGAL_REASONS:
                console.log('rejectedMiddleware', action.payload.status);
                if (action.payload.data && action.payload.data.message) {
                    dispatch(snackbarsSlice.actions.addSnackbar({
                        type: SnackbarTypes.ERROR,
                        message: action.payload.data.message,
                    }));
                }
                break;
            default:
                dispatch(snackbarsSlice.actions.addSnackbar(action.payload.data));
                break;
        }
    },
});

handleFulfilledMiddleware.startListening({
    predicate: (action: AnyAction) => {
        return isFulfilled(action);
    },
    effect: (action: AnyAction, { dispatch }) => {
        const message = action.payload?.message;
        if (message) {
            dispatch(snackbarsSlice.actions.addSnackbar({
                type: SnackbarTypes.SUCCESS,
                message: message,
            }));
        }
    },
});

export const { reset, addSnackbar, removeSnackbar } = snackbarsSlice.actions;
export default snackbarsSlice.reducer;